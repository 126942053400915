.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

main {
  max-width: calc(100% - 260px);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.3s ease-in-out;
}

.arrow--right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow--down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow--left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.ds-none {
  display: none;
}

.inner-msg {
  width: calc(100% - 280px);
  height: calc(100% - 115px);
  position: absolute;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 2em;
  color: #bbb;
}

@media screen and (max-width: 1011px) {
  #search-button {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 959px) {
  #search-button {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 764px) {
  #search-button {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 959px) {
  main {
    max-width: 100%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
